<template>
  <div :class="'c-app' + ' page-'+page">
   <!-- <TheSidebar/>-->
    <CWrapper>
     <!--   <TheHeader/>-->
      <div class="c-body">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
      </div>
    <!--  <TheFooter/>-->
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  computed: {
    page() {
      return this.$root.$route.name;
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
